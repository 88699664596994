<template>
  <modal-2 ref="modal">
    <template slot="title">Desactivar Encuesta</template>

    <custom-form @submit="onSubmit">
      <custom-input
        v-model="password"
        label="Contraseña"
        type="password"
        rules="required"
      />

      <template slot="buttons">
        <custom-button type="button" variant="secondary" @click="closeModal">
          Cancelar
        </custom-button>

        <custom-button type="submit" variant="primary">
          Desactivar
        </custom-button>
      </template>
    </custom-form>

    <confirm-dialogue ref="confirmDialogue" />
  </modal-2>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';

import CustomButton from './CustomButton.vue';
import CustomForm from './CustomForm.vue';
import CustomInput from './CustomInput.vue';
import Modal2 from './Modal2.vue';
import ConfirmDialogue from './ConfirmDialogue.vue';

export default {
  name: 'DisableSurveyModal',

  components: {
    Modal2,
    CustomForm,
    CustomInput,
    CustomButton,
    ConfirmDialogue,
  },

  data: () => ({
    password: '',
  }),

  methods: {
    ...mapActions(['desactivateSurvey']),

    ...mapMutations(['setAlert']),

    async onSubmit() {
      try {
        let ok = this.password === 'Rafaellamanrique01';
        if (ok) {
          await this.desactivateSurvey();

          this.setAlert({
            state: 'success',
            message: 'Se desactivó la encuesta',
          });

          this.closeModal();
        } else
          this.setAlert({
            state: 'error',
            message: 'Contraseña incorrecta',
          });
      } catch (err) {
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtalo nuevamente',
        });
      }
    },

    openModal() {
      this.$refs.modal.open();
    },

    closeModal() {
      this.$refs.modal.close();
    },
  },
};
</script>

<style></style>
