<template>
  <div>
    <view-hero>
      <template slot="title">Ajustes</template>
    </view-hero>

    <div class="container">
      <div class="col">
        <h2>Logo</h2>

        <logo-editor />
      </div>

      <div class="col">
        <h2>Encuesta</h2>

        <div class="info">
          <span>Encuesta Actual:</span>
          <span>{{ survey.name }}</span>
        </div>

        <div class="info">
          <span>Fecha de T&eacute;rmino:</span>
          <span>{{ endDate }}</span>
        </div>

        <div class="info">
          <span>Activar Encuesta:</span>
          <span>
            <custom-button
              variant="primary"
              type="button"
              @click="
                survey.active ? openDisableSurveyModal() : openEnableSurveyModal()
              "
            >
              {{ survey.active ? 'Desactivar' : 'Activar' }} Encuesta
            </custom-button>

            {{ survey.active ? 'Activada' : '' }}
          </span>
        </div>

        <div class="info">
          <span>Vista Previa</span>
          <span>
            <custom-button
              variant="secondary"
              type="button"
              @click="$router.push('/preview/welcome')"
            >
              Ver Vista Previa
            </custom-button>
          </span>
        </div>
      </div>

      <div class="col">
        <h2>Cuenta</h2>

        <div class="info">
          <span>Email:</span>
          <span>{{ userProfile.email || userProfile.user }}</span>
        </div>

        <div class="info">
          <span>Compañía:</span>
          <span>{{ company.name }}</span>
        </div>

        <div class="info">
          <span>Contraseña:</span>
          <custom-button
            type="button"
            variant="secondary"
            @click="openChangePasswordModal"
          >
            Cambiar contraseña
          </custom-button>
        </div>
      </div>

      <div class="col">
        <h2>Cerrar Sesión</h2>
        <custom-button type="button" variant="secondary" @click="logout">
          <font-awesome-icon :icon="icons.logout" slot="icon" />
          Cerrar Sesi&oacute;n
        </custom-button>
      </div>
    </div>

    <change-password-modal ref="changePasswordModal" />

    <enable-survey-modal ref="enableSurveyModal" />
    <disable-survey-modal ref="disableSurveyModal" />
  </div>
</template>

<script>
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import { mapState, mapActions } from 'vuex';

import ChangePasswordModal from '../components/ChangePasswordModal.vue';
import LogoEditor from '../components/LogoEditor.vue';
import CustomButton from '../components/CustomButton.vue';
import ViewHero from '../components/ViewHero.vue';
import EnableSurveyModal from '../components/EnableSurveyModal.vue';
import DisableSurveyModal from '../components/DisableSurveyModal.vue';

export default {
  name: 'Settings',

  components: {
    LogoEditor,
    ChangePasswordModal,
    CustomButton,
    ViewHero,
    EnableSurveyModal,
    DisableSurveyModal,
  },

  data: () => ({
    icons: {
      logout: faSignOutAlt,
    },
  }),

  computed: {
    ...mapState(['userProfile', 'company', 'survey']),

    endDate() {
      return new Date(this.survey.endDate).toLocaleDateString('es-US', {
        timeZone: 'UTC',
      });
    },
  },

  methods: {
    ...mapActions(['logout']),

    openChangePasswordModal() {
      this.$refs.changePasswordModal.openModal();
    },

    openEnableSurveyModal() {
      this.$refs.enableSurveyModal.openModal();
    },

    openDisableSurveyModal() {
      this.$refs.disableSurveyModal.openModal();
    },
  },
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--lengthMd1);
  gap: var(--lengthMd1);
}

.col {
  padding: var(--lengthMd3);
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm1);
}

h2:not(:first-child) {
  margin-top: var(--lengthMd2);
}

h2 {
  margin-bottom: var(--lengthMd2);
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--lengthMd1);
  font-weight: var(--medium);
}

.info + .info {
  margin-top: var(--lengthMd2);
}

.info span:first-child {
  color: var(--fontColor3);
}

.info > *:last-child {
  margin-top: var(--lengthSm1);
  color: var(--fontColor1);
}

.hero {
  background-image: url('../assets/modules/settings.jpg');
}
</style>
